import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Node-RED  with your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/Node-RED_for_INSTAR_WQHD_MQTTv5_Cameras/",
  "dateChanged": "2022-08-18",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of Node-RED. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED  with your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-18' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 3 of Node-RED. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/Node-RED_for_INSTAR_WQHD_MQTTv5_Cameras/' locationFR='/fr/Frequently_Asked_Question/Node-RED_for_INSTAR_WQHD_MQTTv5_Cameras/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "node-red-with-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-with-your-wqhd-instar-mqttv5-broker",
        "aria-label": "node red with your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED with your WQHD INSTAR MQTTv5 Broker`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my IN-9408 2k+ with the new Version 3 of Node-RED. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by dragging an `}<strong parentName="p">{`MQTT-IN Node`}</strong>{` onto the Node-RED canvas and double-click it to configure the MQTT Broker. In the `}<strong parentName="p">{`Server`}</strong>{` field select `}<em parentName="p">{`Add a new mqtt-broker...`}</em>{` and click on the pencil icon to edit it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1da95298bc1b26cee844db36d4267be5/69476/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABnklEQVQoz2XQy2rbQBiGYV2tC3aDHNKNb6ZQWmhDSqFJlZrYseJYGs/oYLmr0nSThZhqYyMpmvNBaaUQHPrwbV/+YRz36OjN8bE7Ho9Go8Fg8KozHA5fd1zXHf/PdU8mk5PJxAEABGEQRVEcxwAABNcAgLu7338wzvN8t9uVL1Vl+dA09/Ob+/mNgyAKliuwAnGcpBFafZ8uvGme55TSpmkYY5RSzjk7QDn/+e7jrw+nDgzh7Px69nUOQ5hlmzRJYoQwxlVVPdS11VoJoYQwSvWzSgnOmRTKWgdB4M+/LWYXEIJtJ03Toig45621pK7L/b7c7zkhtu+lFJwbY1prHQhvF/7pcnkWRcE2+5FlWZIkRVEIIdq2ZYQ0VUXqmjaNkbKfltJo3batg1Dg+18W158RCrbbpxhjTAixxkjGrFKt1v1sPyntczybnU2nnyBcPcdFUTDGjDFSSikE51wKoaRUnf7N/2IA/MvLt1dX79fr5WFMCNFaU0rrum5eeuq7OPS8C887ByDMsmyz2cRxjDHm3a8opWx35JDt/I0fAR88+5NjMMvrAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1da95298bc1b26cee844db36d4267be5/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/1da95298bc1b26cee844db36d4267be5/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/1da95298bc1b26cee844db36d4267be5/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/1da95298bc1b26cee844db36d4267be5/72ef3/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1da95298bc1b26cee844db36d4267be5/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/1da95298bc1b26cee844db36d4267be5/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/1da95298bc1b26cee844db36d4267be5/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/1da95298bc1b26cee844db36d4267be5/dafe9/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1da95298bc1b26cee844db36d4267be5/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/1da95298bc1b26cee844db36d4267be5/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/1da95298bc1b26cee844db36d4267be5/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/1da95298bc1b26cee844db36d4267be5/69476/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1da95298bc1b26cee844db36d4267be5/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera is configured with the default values:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95204af95b8f5be5908e6b56f52f3051/2bfc7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB6ElEQVQoz1WRzW/SYBzHuRkn6ytkB2ifPi2xLfTlbAYDBBm0UtrnpWu7YagXMRnJEnfwohcTt4MHE03mQf9X01ZEP/lev/l8f/nVLMvqlZimYXTt8ev76e7ncPswevvjkO3D4ubXNP8EFNV13dFo1Gq1AAC13h7TMHq2i7cf05sv5PquyO6O7O6LXH++vP26zN+fNE7aLUlVtLasQLVTlLslhmHYjoNWPo1e4sAjoY+DBVrOUbCIluc4Wk2eT7mjY54VOUYAUIPq0z/mbrer67rruhgTSulmk+d5fnW1xoRQGhNCkySZz+dcnROFpsCJMoAA7s1mcbHhOE4cx1mWpUlCCImiiBCCMQ7DME1Tz/NEThCFJs+KEoAK1GrV5qps2zYhJMuyOI5xyWw261cMzvqDociJAtfgWUGSoQy0/8y2bWGC0zRNkgRjjBCKSsJVkGTrhR/xdZZnRZ4VZRlKMizMVf+vOSk3o3+IwvAiW8+9kK9z7LHAc0JbAodyYdZ1x3VpHMeUVp1i9nQy7D/rnw1OJ9PTwYg5Yh4/eiLwTQloQDNqpmlalnV4FcZ0X0YoCsilTzfUD3LPW4zHDMMLfEOGHaVjKJp+MFd4nlddixBG4Yq++YBvv78jr775wfrFOdBUTS2AJb8BUv+bStpYUeQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95204af95b8f5be5908e6b56f52f3051/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/95204af95b8f5be5908e6b56f52f3051/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/95204af95b8f5be5908e6b56f52f3051/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/95204af95b8f5be5908e6b56f52f3051/29541/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95204af95b8f5be5908e6b56f52f3051/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/95204af95b8f5be5908e6b56f52f3051/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/95204af95b8f5be5908e6b56f52f3051/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/95204af95b8f5be5908e6b56f52f3051/79a76/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95204af95b8f5be5908e6b56f52f3051/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/95204af95b8f5be5908e6b56f52f3051/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/95204af95b8f5be5908e6b56f52f3051/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/95204af95b8f5be5908e6b56f52f3051/2bfc7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1227w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95204af95b8f5be5908e6b56f52f3051/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And your camera uses the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.118`}</code>{` use the following configuration to connect the Node-RED MQTT client with your camera:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6acd36deffc44b144309b901fcaf4531/cc488/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACBUlEQVQoz32SXYvaQBSG87O7IEiVKnYpBW/a3pbSZaEsW6NlXW3UipMPIZEE2ste9HITTeZ74sykJNO1stA+l8M85z3ncKzn7faLbrfT6bTb7WcXF61Wq9fr9fv9Xq/Xaeh2u4PB4OU5l5ev3755NRxaQRB4vhc1bINgF4ZFURBCiqJ4eHhIGwgh9BFelvhw+DF89+vesTzgLe4Xy9nS9/wAbMBXJ02zLMsQQkIIzrkQ4mSSpgw+5N8/XP9cri0f+LPP89lo7gEvjMIkSfI8hxBKKTnnjDEhhFKqOkNrrarqqJUVBGDh3C6cW98HURQlSYIxRgixxiSE7Pd7xljZYPJLUZZcyKO0PG/tONer1c12uwnDP3Ke50IIrbQQAkKIMSYNh8MhyzIIYV2iLOtkx7mZzz/5/iaKdrVMiFIKc57u9xBCxpiUUj8ipRRCVFWllLJ8f313dzWdXnneOop2cRyXZVlVFaQ0hwVjjHP+ZGZDLbvuajJ5P51+dN1vJhkhRCnVSmGMj8ej+XeOlPIku5OaL67rRlEUx3FRFEbLi9zE/k+27dFkMgYAnGSz7QahtX7SsHmpZQCAbduj0cjISZJAhDhlKUZpnhOMTc6/ZnZt2x6P/yabhUkpUQOl1ByMlPLUeX0nJtlxHABAEARhWF8YIaRumzFKKULI3Aw+AyHEOdda/wZjoOLmGYkHcQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6acd36deffc44b144309b901fcaf4531/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/6acd36deffc44b144309b901fcaf4531/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/6acd36deffc44b144309b901fcaf4531/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/6acd36deffc44b144309b901fcaf4531/d6d20/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 928w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6acd36deffc44b144309b901fcaf4531/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/6acd36deffc44b144309b901fcaf4531/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/6acd36deffc44b144309b901fcaf4531/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/6acd36deffc44b144309b901fcaf4531/5bce2/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 928w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6acd36deffc44b144309b901fcaf4531/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/6acd36deffc44b144309b901fcaf4531/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/6acd36deffc44b144309b901fcaf4531/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/6acd36deffc44b144309b901fcaf4531/cc488/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 928w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6acd36deffc44b144309b901fcaf4531/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And add the broker login on the `}<strong parentName="p">{`Security`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/568510d22d25b20e35625010a0fb234d/e0885/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABWklEQVQY03XOTU/CQBAG4P5kPUDkK0hiohe9efLohUTjZ6JRWmuxpaHtKsYQy4G7oRTosrvdnW5bQ5XIxec0yeSdeZV6pdqo1au1WqlU2treLpXLjUajXtgpVCqVZrO5u7aaW629o8PWwb7iOI7ruoP3wdtg4PT7n8MhK2CMgyCYTqez2SyOY74mkoSE4fi0/WXaivViGZphPpseQrZhIMvGy2UYhpRSUQCAeJMQJJh+HJ+M7zuKqZsPl4/qrea5HkKe738SQjDGaZpKKaGQpmm2Ic8ymecxgGKZ+pN6Zeh3yHMRQqPRiDEmhOCcLxaLKIowxgAgpUySZFW72CUCJIDS66m6ftbtXiP0G6aUTiaTKIoopXEcz+dzAMjzXEoZBEEYhqQghFAsS1U7bU079zwHoVff9wkhlFLOOWMsy7J0w1/z4pZi27qmXej6zeZnxhgAcM7Tf/yEvwEZrJldDMZX8AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/568510d22d25b20e35625010a0fb234d/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/568510d22d25b20e35625010a0fb234d/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/568510d22d25b20e35625010a0fb234d/6d4a2/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/568510d22d25b20e35625010a0fb234d/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/568510d22d25b20e35625010a0fb234d/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/568510d22d25b20e35625010a0fb234d/56bc7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/568510d22d25b20e35625010a0fb234d/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/568510d22d25b20e35625010a0fb234d/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/568510d22d25b20e35625010a0fb234d/e0885/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/568510d22d25b20e35625010a0fb234d/e0885/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`On the `}<strong parentName="p">{`Messages`}</strong>{` tab we can now define a `}<em parentName="p">{`Last-Will`}</em>{` for Node-RED, e.g. on the MQTT topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`nodered/lwt`}</code>{`:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Event`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`MQTT Payload`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Connection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{"val":"alive"}`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Disconnect`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{"val":"offline"}`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected Disconnect`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`{"val":"dead"}`}</code></td>
        </tr>
      </tbody>
    </table>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/e4374/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACa0lEQVQ4y32TzW/TMBiH81/vUgFBk8YQ0i5w4gIc0GAHtpUybW363WRxPlZNajLtwgk1rZYmTmwnju0GJd6iDU08h8iXR3nf389W1Bcvd9XXqqq2Wq2dnZ1Wq7X7wKsaVVX39vbePGJ/f//dh/dvDw4UAIAJzPl8fjW/soC5uL5GCBFCkiS5u7sLw3Cz2RBCsgcoY2gT3X78/Kc3VIyZMeqNJv2JbdvmdALG0ziOwzDEGNOaoijyhsrOSQz9L4e/taEyG+nnx91uuwdM4Lqu5/tJkiCEOOdSo5QKIbZP4WVJWaEY+migHQ8HbdsyHcfxvAVCSP4T1sRxnOd5URRyCsYY53wrBGdM0Y1Bv380Gv2wbdNxXN/3EULL5TIMQ4QQhHC9XkdRBCHEGG+qBDZCCM45pVQxjKGmfde0I8syatkjhARBsKqRhyAIwjCUK2dZRgiRiSi63j8//3px8Q0AKftpmiKEyrKEEBJCyrKUewohZBGMMUII51yZTrudzqdu97CREUJRFDHGMMZRFFUBU8pqMMZ5nsu1GWPKZDLpdH6enf0CwHRdd7FY5HmepmlRFFmWybEhhElNmqbysN1uK3k8Hp+ennY6HdOsZM/zpCw3hBCmaQqf8q/cbrel7Ps+pbSRZc7PykKISj45OWlkz/MopbLqLMviOP6fPJvpg8FwOp1ZluU4ju/7WZY1O8dxnDzHfWCXl7phjAHQbftelj0HQbBcLlerlfwGQbBer5vnQQjJ81wxzYGmHY7H9zfs5qaqqukZISQegTEmhMihqp5ns2m/3zNNoxlb3krOOUIoSZLHMiFEvhNZ+1/vDn9Y0/ZAVQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/fd76b/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 927w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/4c2b6/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 927w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/e4374/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 927w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ced43e3e5a5b0546f8be07a2c66d00b9/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After saving those settings we can now subscribe the MQTT node to one of our camera state topics - e.g. the `}<strong parentName="p">{`Broker Uptime`}</strong>{` topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$SYS/broker/uptime`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/69476/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB6klEQVQoz2WRT2+bMByG+b7dFE1qytRL+zV2qHbYYVV3yFba5g8JJCR0UmwgOUz7AlungcwfGwwOtjMZBMq252D54Ee/1+9P08/P9YuL4XA4GAzOzl69bnjToev62//Qdf3y6ury+loDAEAP7vd7P/AhAL7nAQBeXl6SJEEIUUrLf6CUCf776/an5Wib9caxnee1CyHcuq5ljO3pPAzDLE0xxkVDZylKSktef/9w++3de2292ExHpmksIICB7/lg6wOAYoQxLstSSskbhBCygzFWHg4HITR3bVvm/coaQwiCYBfsdr7vI4QYY1LKoigwxoSQoihEA+dciZxLJT/P7OWds/7ieSBo6OXj8ZjnOcY4yzJKqejog2iua87nd7Y9gnDby1EUtZkJIVmaYYw55/JvhBDaZjObzT6a5icAtip2J1NK1Yuat9+WR4VsJjfXo5KXy/vHx5v5/LaZvOtjV2XJ6vpHGKqNIRRFURSGBKvP88ZX8mplPT19nk4fAACnsauywjn5FUVJHFdVxaqKMSakSiCbU8m2vTSMh/F40sue58VxrDJzXuR5kiRZlhGSt1X1m+Oca5a1GI1GhmGcTkYItbvJ8zxN06yjbV5FaNAcx5lMJqZpQghP5aqq2j0TQuq6btP2Pbdt/wGM+p/wxhwlyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 230w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 460w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 920w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/72ef3/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 230w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 460w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 920w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/dafe9/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 230w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 460w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 920w", "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/69476/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 926w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf72cd6bc80c27e05d391a0f1f904ec0/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Connecting a `}<strong parentName="p">{`Debug Node`}</strong>{` i am now able to see the amount of seconds the camera broker is online - as a proof of concept:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "905px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d1687a523f5b5bba5f5f6b50180361e/65d79/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABL0lEQVQY0y2Ny0rDQBSG83AiLgNSK4hL3fYNfJri2tJaFRcq7gRB8FZF0F5iWjXJzJzkzO3MGQn6LT/+ny/ZTNOdbndru7u+ttE72F2Vi3k2zfJZvloAKkClaiGg/C6WgHI0Huzt7fd6vU6nk6ZpEgI5ZyPHGKN3JCuppELUWqPRYDRYg9ZaqVRVVUQUQtDGIDYxxkRIePtYTrMCGo2Ik9uXyc3z9Gn+U+Rn14fHF/27x6tGo6yEFEJJURZFrZTRLck0z/rjYf9kOFvmzKzq2hrrvSfy3qI3DXlnnWuEMELYGpy13jpmDiEki2oxuD8aPgw+ZRZjbG3r/+AQmJmtc6osoapQqQbAIHIIRJTAV/5+efp6PlKrnGOktkn/X6JAxCE45zSAFcIBtAPv2wLzL5J9QT3CwNwIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d1687a523f5b5bba5f5f6b50180361e/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 230w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 460w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/8df31/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d1687a523f5b5bba5f5f6b50180361e/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 230w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 460w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/4d060/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d1687a523f5b5bba5f5f6b50180361e/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 230w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 460w", "/en/static/7d1687a523f5b5bba5f5f6b50180361e/65d79/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 905w"],
              "sizes": "(max-width: 905px) 100vw, 905px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d1687a523f5b5bba5f5f6b50180361e/65d79/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And now to more practical things - you can find a couple of `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/#example-application-node-red-flows"
      }}>{`example Node-RED flows in our MQTTv5 API documentation`}</a>{`:`}</p>
    <h3 {...{
      "id": "network-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network-configuration",
        "aria-label": "network configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Configuration`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e32bec185085731e63f105305ca46069/6dd26/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFElEQVQoz43Q2XLSAACF4TxlSUkqdbzW8UF8AC/1CdR2sBWaFEoLJSvZE0KzJ5CQlUUo2Rxbt0u/+3/OzAHevnl9enr6AoJenpw0oROo9aoJwWCjAUFQo9FoNpvHx8cgCMIwfHR0BIJg4wkMw61WC4hXu8eyMtLM3e6qQ1ZnRl3t6/8DfLk9c8OAthzJX2ZzLVFHh11W1nWe52VRFHl+yIu8qIqyKOsyL/LDPwBi0I3jSFQt0/b3oZ6GS3s2t2RrHa/MJPHX2zwL1q60MH1TMtNlWtVVURTlE4Di5XkQfUKJ7j2fChfm5G78jcQvMW0yu9eNvmYHBhezbXbIj9ojjdUOj4fyN8A2jWWYnPVIFBMzseNyGIEyFEItNA83rKHpJraUcBfCWCS7ZGAHf2Z/xpvVNoqzzyiOjIVUuLSZMdahKYTkb1hJMhHD8TQmY9vsSMQ7BDdgfNOv6upXnBpoFPrnN3QPF1dS1+YwApnQCPVAq7proJpj6UIidKYYQ12RwkjZZNu/bxvyu2VotwdsnxAzGbE5gkAn9DXND3lVPPecK23hYzOV44eqwM2GtwqpzPV5YAeRFwEu9SFceu3B5Dl2nmOEUkhZUz4mAS54wfVMXygDTyaFuwmJEM7U2X/fb7IN4DwkYZR9vaX7pLxSEIcnqB7H9BiNU/Xp+3WikHZAWPZuingCTvYEts+s43Vd10VR/AB/xCU8KjZuhwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e32bec185085731e63f105305ca46069/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 230w", "/en/static/e32bec185085731e63f105305ca46069/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 460w", "/en/static/e32bec185085731e63f105305ca46069/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 920w", "/en/static/e32bec185085731e63f105305ca46069/e1c99/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 1380w", "/en/static/e32bec185085731e63f105305ca46069/14ccb/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 1597w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e32bec185085731e63f105305ca46069/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 230w", "/en/static/e32bec185085731e63f105305ca46069/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 460w", "/en/static/e32bec185085731e63f105305ca46069/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 920w", "/en/static/e32bec185085731e63f105305ca46069/445df/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 1380w", "/en/static/e32bec185085731e63f105305ca46069/bd1ed/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 1597w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e32bec185085731e63f105305ca46069/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 230w", "/en/static/e32bec185085731e63f105305ca46069/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 460w", "/en/static/e32bec185085731e63f105305ca46069/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 920w", "/en/static/e32bec185085731e63f105305ca46069/b1001/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 1380w", "/en/static/e32bec185085731e63f105305ca46069/6dd26/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 1597w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e32bec185085731e63f105305ca46069/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "multimedia-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#multimedia-configuration",
        "aria-label": "multimedia configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Configuration`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/ae28e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB/klEQVQoz42Oa0/aUABA+///wJAWcS4aM83MlixAJw91KH1B6e3r9vZx6YPSB3JLixGDdeEf7Hw7H05yqKuLi5vv1+1W65Rutlst+vTs9Ou3k5MThmFomm42m60W86XRoGmaOVqTYZhGo9Fut8/Pz6nlhvj5evP+Dl42L+/7T4Lqwvv8PyhJG/8VukGSPaowSlbbxewlcMi6IGtSrLe7Mq/KeF2+FuX2bZuXZEtyQnJSFdV+v6dsw9JkxQtWN6ygK7OdNdBFyPd48U6YPxieeYPd676zgq62Bb/1icL3BJEVQhjWnzW1iCLXX9iL5UWHA/K00jvgWRF6wrQvzu91DG+xe3VnxyoCpdrRJgrPihIreqr3UX9QhJCCkHCV3/GGa1uvCw4BZPCmJUEP+LHfTRNeivJwiXfexAaWKUAowCzM6rqminT5tkmCOGM5zUFwhyeWYmkTHYqmPcNLPIqjoRTlwdKrnGekQJ0zTN5Ig/QYp0Fn5V06fnzZ5cB8WhpdlQN8T5D6ojzSsfkTO8dtDYFS62icwrOCyIpYx8ftxPu1ss5wmN4Op6YGdmioS9psOJs/yDqHfPtHFPYfcYIwrODAENXZSJZHcoSiw8eBSv0gcbwgzjpjYEPj1X2yFQTGqjZRoeRG3p84GvBB5odOZY/RHIInVX1SMz87HA7/ALib9zJnb8QqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e253500dfff1a403f9bb5b2c5789d8/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 230w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 460w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 920w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/e1c99/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 1380w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/8b791/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 1598w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a4e253500dfff1a403f9bb5b2c5789d8/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 230w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 460w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 920w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/445df/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 1380w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/2eb6a/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 1598w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a4e253500dfff1a403f9bb5b2c5789d8/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 230w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 460w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 920w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/b1001/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 1380w", "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/ae28e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 1598w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a4e253500dfff1a403f9bb5b2c5789d8/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "alarm-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-configuration",
        "aria-label": "alarm configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Configuration`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/fde66/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACBklEQVQoz1WQyW7TUABF/bsoitMmRCyKRCk/wIYtrNgwSJUaUShpIOmYyUOdxm48D7Hfs9+Lh9ipU9sxSiuEOLt7pbO4l3i1t3fwer9Rrzd2as/rdXK3sdt8QT5Sq5HVanVnh6xUnlWr26ZSqdRqNZIkG41Gs9kkolXqJ+v7TaktkyDLy7W7Se2y3JRbirIs04RdRb8e41P5D2LA90/633TX7Ymy5oJAGWNxlK5WWZ5Hwe0C25r8XRbeY4zTNN0URZFnD38hZH7K9a8chM+niqNOE5MROWNyKSDTM4SXhtIC9pBn913AQoj9OEZxkud58QihWvZUVLwgokTDcYAvUQtvvgw9G/ns7VtTPwPWFc8eYCR5LkZh6AThQ5Y9+cRigV0IgijSZQtoc0uyAmQmiUBB0NFVf7WB8zOJexNgJSv+m72VoyC8X8bhMp6Z0MW4CI3Q84G+8E03soOlr5v6ESP1LIzXSAkhdFToqLZnedk6I5B5gZRDwwYfj68HYya+bQlDpn88GJ4MLA05ZksWP7VlqEEQT45Umr46HjEdOvTC7dvQalniOwuAr6dj+maS3J3MaG58SrNdRhiJ2uyHonzuitKlosG7c5Prsz2G7bLaVEM2IpDKudOLOXC/tIcUy21lihu1KbZL0x1Onlwb0oeBzB/eCNfizLvrGeyA+s2Of474Pv8HIWL3ZZSHsV0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ab4e98abcd16e32c7de360ac736fc4d/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 230w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 460w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 920w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/e1c99/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 1380w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/f7f18/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 1574w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ab4e98abcd16e32c7de360ac736fc4d/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 230w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 460w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 920w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/445df/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 1380w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/b5a92/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 1574w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ab4e98abcd16e32c7de360ac736fc4d/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 230w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 460w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 920w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/b1001/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 1380w", "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/fde66/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 1574w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ab4e98abcd16e32c7de360ac736fc4d/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To import them into Node-RED first copy them:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/f09ab/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "10.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAf0lEQVQI12Oo3fY7e/3/4s3/s9aBUO/B/9OO/Z9+/P+Uo/+nHv0/6cj/ngMgtOnq/+MP/999+//33/8/f///AUYMsUt/20797z33v/OM/04z/kcs+R+99H/Msv+xy/+HLvqfsOJ/xtr/mWtBjOLNIHMXnP4/5+T/uaf+zzj+HwDND1qlpBDBlAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 230w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 460w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 920w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/09946/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 230w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 460w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 920w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/e19c7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 230w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 460w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 920w", "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/f09ab/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 1250w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba8f6dbcd46b1d6a71a2b1c330482298/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now open the `}<strong parentName="p">{`Import`}</strong>{` dialogue in Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bbc5f32dadcd31acdff7629f871c3a50/23592/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACWklEQVQoz22R3U/aYBTGe82KNKutAeKgJiYGLgyX7nIrsAu9nNvF/rMli6Ikus0ly7JonBh1m8tQy4cCLSAFKlBFKP2ufbHvAm53++XkJCcnT54n5yBvXr1+uby8tLRIR6PRWCwWj9M0TT9/FouOOk3TsVjsRTy+tLgYDoVQ9NH8/HwkEvF6vW4URSzTgI4DIRwCG1jWPQAPI4TQGddoNRxCCFcSqxiG4TiOoqjH48EwDDnnBK4mKqrONIQUW06VuGK7ozaY2/PdfmHPGojDe8cyTQjh5uqq3+udnZ198g/k3fbGxsFnSRlkmq0UW96v1BqSotROu9nPWvXQASPZg/hDIuGbmgpSlN/nm56eDgQCyK+Lr+nirqrLnb7E39yYtjWUWrbW01Rb6ir9G0mTNNu2x7ETLpcrEAg8HoPjOMJ82cluf9Nl5WepuvHjLMUUhfzhIPOxljlm9rMn279zB1m+wEMI19bXMAybmZnx+XzEGGQ/Vzq84BRdVzVDvJU6vYFxZwP1+t7sdfuDnijLt4os9iCEyWSSJEm/349h2IM5wlcrTb6mG3qufrWXL1YEsSc026c718UTw1DsO9EGSn0gOxAm19dRFA1SFEEQOI6PnFmO4/m6dWc16leZ43whXeQYlk3nywx33WZb/Pt2++RtOldTtc1k0j0xEQwGJycnCYIgSRKRZVlRFACA8/ep/wEAMDrYyorL5aIoiiTJCbfb4/Eg3W7XMAzTNDm+9f2MPWLKLUHQK0daM9e67FQY7jJXLaRLtgG2Pm3Nzc0tLDyNRCKhUCgcDv8BseOq7/kL0pQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5f32dadcd31acdff7629f871c3a50/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 230w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 460w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 920w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/adc73/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 1031w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5f32dadcd31acdff7629f871c3a50/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 230w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 460w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 920w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/606d6/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 1031w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bbc5f32dadcd31acdff7629f871c3a50/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 230w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 460w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 920w", "/en/static/bbc5f32dadcd31acdff7629f871c3a50/23592/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 1031w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bbc5f32dadcd31acdff7629f871c3a50/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Paste them in and hit `}<strong parentName="p">{`Import`}</strong>{` to import them into Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/49c540c81ecd5df28642eea606178a0e/b12f7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACGUlEQVQoz1WSS2+bQBRG+++7qrrpostKVWv1saiUpI7rkIeMcWM3iQcDJnhgGGAGhocNBhtwDVQxSaMc3cVsjr5Pc++r3jn34VvnR/cE6rpmGBalhPm+zwLX9qkVMhoeCA5EUUQpvXvi1QXPfe9+Or08StO0LIq6quqq2lfVvm6qutlXdV3XzYH2kWXZdDp9lHmO63Y6FyfHURQxxtI0DTyPmCazbc+2GXECSpeMRYwtGdvEcbpeTwG4u719kM8Hw6/HvdPzqzAIPM+L45gahg7AYjqFABizmSGKEACsKAzCyDST1QoAcNvKo9GI4854fhDHcZam2+3WRWgBgKkoRNOIprkQuhD6CPkIhZb1Qh4Oh/1+n+f5JEnKsiyKwjNNCAAURawopiyjw2BFce7vPV1PlstneTKZjEaj8WScJElxwMNYF0VDkkxFQZJkyrIxm1mqygwjxPhlbUEQhsLNn5s4jlvZRahNNiTJVlV3sSCaRhcLT9cDhF7I4yEn9D5fXx4l63UrM4zxfG60bVUVz+cPFWTZhZDp+ioMgSj+T/7J/Xo3uOokSZLn+TbP41XCPD/fbMuiePiDPG/n727XNM1ms3nes8Afn/XeDi4+pmla11VV7ctyt87y/dNttPfRNM0uy0L+9xJbYDZ7TL4edAdH74X+F8dxLIcgy7GI67t24BgeIcQi1CIEO5R6jno/fv1G4wUgS638DzmLcK7Hg/x6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c540c81ecd5df28642eea606178a0e/e4706/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 230w", "/en/static/49c540c81ecd5df28642eea606178a0e/d1af7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 460w", "/en/static/49c540c81ecd5df28642eea606178a0e/7f308/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 920w", "/en/static/49c540c81ecd5df28642eea606178a0e/2c8af/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/49c540c81ecd5df28642eea606178a0e/a0b58/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 230w", "/en/static/49c540c81ecd5df28642eea606178a0e/bc10c/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 460w", "/en/static/49c540c81ecd5df28642eea606178a0e/966d8/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 920w", "/en/static/49c540c81ecd5df28642eea606178a0e/52c2b/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/49c540c81ecd5df28642eea606178a0e/81c8e/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 230w", "/en/static/49c540c81ecd5df28642eea606178a0e/08a84/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 460w", "/en/static/49c540c81ecd5df28642eea606178a0e/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 920w", "/en/static/49c540c81ecd5df28642eea606178a0e/b12f7/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 1020w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/49c540c81ecd5df28642eea606178a0e/c0255/Node-RED_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png",
              "alt": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "title": "Node-RED with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should now be able to control your camera through the MQTTv5 API!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      